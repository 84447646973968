/* eslint-disable no-useless-escape */
import axios from '../../plugins/axios'
// 大屏下面(最新帐本信息)
const listLedger = (options) => axios.post('/chainQL', {
  query: `
{
 listLedger(condition: {
  descSorts: ["timestamp"]
  from:0
  size:10
  eq:{
      shardName:${JSON.stringify(options)}
  }
 }  
)
{
  ledger{
  ledgerCodeName
  ledgerCode
  hash
  result
  timestamp
  }
 }
}` })
// 大屏右边(最新区块信息)
const listBlock = (options) => axios.post('/chainQL', {
  query: `
{
listBlock(condition: {
    descSorts: ["timestamp"]
    from:0
    size:30
    eq:{
       shardName:${JSON.stringify(options)}
    }
}
)
{
  block{
  height
  difficulty
  shardName
  actualDifficulty
  timestamp
  size
  }
}
}` })
// 最新区块高度
const heightMax = (options) => axios.post('/chainQL', {
  query: `
{
listBlock(
    condition: {
      max:["height"]
      eq:{
        shardName:${JSON.stringify(options)}
      }
    }
  )
  {
    total
    heightMax
  }
}` })
// 实际账本统计
const difficultyCount = (options) => axios.post('/chainQL', {
  query: `
{
listLedger(condition: {
  eq:{
    shardName:${JSON.stringify(options)}
  }
}
)
{
   total
}
}` })
// 合约账本数量
const contracts = (options) => axios.post('/chainQL', {
  query: `
{
 listLedger(condition: {
  eq:{
    shardName:${JSON.stringify(options)}
    ledgerCode:201
  }
 }
) {
   total
 }
}` })
// 可编程账本统计
const future = (options) => axios.post('/chainQL', {
  query: `
{
 listLedger(condition: {
  eq:{
    shardName:${JSON.stringify(options)}
    ledgerCode:301
  }
 }
  ) {
   total
 }
}` })
// 可编程模板统计
const temp = (options) => axios.post('/chainQL', {
  query: `
{
 listLedger(condition: {
  eq:{
    shardName:${JSON.stringify(options)}
    ledgerCode:0
  }
 }
  ) {
   total
 }
}` })
// 账户统计
const accountAlgorithmCount = (options) => axios.post('/chainQL', {
  query: `
{
  listAccount(condition: {})
  {
    total
  }
}` })

const shard = (options) => axios.post('/chainQL', {
  query: `
query getAliases{
  getAliases
}` })
// 大屏左上折线图
const blockLine = (options) => axios.post('/chainQL', {
  query: `
{
listBlock(condition: {
descSorts: ["timestamp"]
  from:0
  size:7
  eq:{
    shardName:${JSON.stringify(options)}
  }
}
)
{
  total
  block{
  height
  difficulty
  actualDifficulty
  timestamp
  size
  }
}
}` })
// 大屏左中间折线图
const blockChart = (options) => axios.post('/chainQL', {
  query: `
{
listBlock(condition: {
  terms:["timestamp"]
  eq:{
      shardName:${JSON.stringify(options)}
  }
}
)
{
  timestampTerms {
      key
      value
    }
}
}` })
const getNode = (options) => axios.post('/chainQL', {
  query: `
{
getNode(condition: {
 ip: ${JSON.stringify(options.networkIP)}
 port: ${options.networkPort}
 json: "{\"mode\":\"System\",\"action\":\"info\"}"
})
}` })

// 获取区块列表（根据条件搜索）
const blockList = (options) => axios.post('/chainQL', {
  query: `
  {
  listBlock(
    condition: {
      descSorts: ["timestamp"]
      from:${options.page}
      size:${options.limit}
      gte:{
        timestamp:${JSON.stringify(options.startTime)}
        height:${JSON.stringify(options.startHeight)}
      }
      lte:{
        timestamp:${JSON.stringify(options.endTime)}
        height:${JSON.stringify(options.endHeight)}
      }
      eq:{
        shardName:${JSON.stringify(options.shard)}
        height:${JSON.stringify(options.height)}
        hash:${JSON.stringify(options.hash)}
      }
    }
  )
  {
    total,
    block {
      shardName
      promiseCode
      height
      size
      coinBase
      parentHash
      timestamp
      timeline
      difficulty
      version
      hashAlgorithm
      hash
      signAlgorithm
      sign
    }
  }
}` })
// 账本列表（支持类型搜索）
const tableLedger = (options) => axios.post('/chainQL', {
  query: `
  {
  listLedger(
    condition: {
      descSorts: ["timestamp"]
      from:${options.page}
      size:${options.limit}
      gte:{
        timestamp:${JSON.stringify(options.startTime)}
      }
      lte:{
        timestamp:${JSON.stringify(options.endTime)}
      }
      eq:{
        ledgerCode:${options.ledgerType}
        shardName:${JSON.stringify(options.shard)}
        blockHeight:${JSON.stringify(options.blockHeight)}
        sendAddress:${JSON.stringify(options.sendAddress)}
        hash:${JSON.stringify(options.hash)}
      }
    }
  )
  {
    total
    ledger {
      actualDifficulty
      contract
      count
      daHash
      dataSources
      depositHash
      errorCode
      expirationDate
      exponent
      fees
      json
      languages
      ledgerCode
      ledgerCodeName
      blockHeight
      message
      name
      owner
      parentHash
      reHash
      recall
      receiveAddress
      redemptionTime
      redemptionTimestamp
      remark
      result
      sendAddress
      sendAlgorithm
      sendSign
      statusKey
      statusVersion
      token
      txHash
      unit
      value
      verifyAddress
      verifyAlgorithm
      verifySign
      hash
      hashAlgorithm
      nonce
      promiseCode
      shardName
      timestamp
      version
    }
  }
}` })
// 账本列表(查个性凭证账本列表)
const _tableLedger = (options) => axios.post('/chainQL', {
  query: `
  {
  listLedger(
    condition: {
      descSorts: ["timestamp"]
      from:${options.page}
      size:${options.limit}
      eq:{
        owner:${JSON.stringify(options.publicKey)}
      }
    }
  )
  {
    total
    ledger {
      actualDifficulty
      contract
      count
      daHash
      dataSources
      depositHash
      errorCode
      expirationDate
      exponent
      fees
      json
      languages
      ledgerCode
      ledgerCodeName
      blockHeight
      message
      name
      owner
      parentHash
      reHash
      recall
      receiveAddress
      redemptionTime
      redemptionTimestamp
      remark
      result
      sendAddress
      sendAlgorithm
      sendSign
      statusKey
      statusVersion
      token
      txHash
      unit
      value
      verifyAddress
      verifyAlgorithm
      verifySign
      hash
      hashAlgorithm
      nonce
      promiseCode
      shardName
      timestamp
      version
    }
  }
}` })

const listLedgerType = (options) => axios.post('/chainQL', {
  query: `
  {
  listLedgerType {
   label
   value
 }
}
` })

// 根据区块哈希或区块高度查询区块信息（区块高度查账本）
const blockDetail = (options) => axios.post('/chainQL', {
  query: `
  {
  listBlock(
    condition: {
      eq:{
        shardName:${JSON.stringify(options.shard)}
     }
     or:{
       hash:${JSON.stringify(options.hash)}
       height:${options.height}
     }
    }
  )
  {
    block {
      shardName,
      promiseCode,             
      height,                 
      coinBase,                
      parentHash,              
      timestamp,               
      timeline,                
      difficulty,              
      version,                 
      hashAlgorithm,           
      hash,                   
      signAlgorithm,           
      sign
      size
    }
  }
}` })

// 根据区块高度查询区块信息（区块高度查账本）
const blockForHeight = (options) => axios.post('/chainQL', {
  query: `
  {
  listBlock(
    condition: {
     eq: {
         height:${options.height}
         shardName:${JSON.stringify(options.shard)}
     }
    }
  )
  {
    block {
      shardName,
      promiseCode,             
      height,                  
      coinBase,                
      parentHash,              
      timestamp,               
      timeline,                
      difficulty,              
      version,                 
      hashAlgorithm,           
      hash,                    
      signAlgorithm,           
      sign
      size
    }
  }
  listLedger(
    condition: {
      descSorts: ["timestamp"]
      from:${options.page}
      size:${options.limit}
      eq: {
        shardName:${JSON.stringify(options.shard)}
        blockHeight:${options.height}
      }
    }
  )
  {
    total
    ledger {
      actualDifficulty
      contract
      count
      daHash
      dataSources
      depositHash
      errorCode
      expirationDate
      exponent
      fees
      json
      languages
      ledgerCode
      ledgerCodeName
      message
      name
      owner
      parentHash
      reHash
      recall
      receiveAddress
      redemptionTime
      redemptionTimestamp
      remark
      result
      sendAddress
      sendAlgorithm
      sendSign
      statusKey
      statusVersion
      token
      txHash
      unit
      value
      verifyAddress
      verifyAlgorithm
      verifySign
      hash
      hashAlgorithm
      nonce
      promiseCode
      shardName
      timestamp
      version
    }
  }
}` })

// 根据区块高度查询账本列表（区块高度查账本）
const blockHeightLedger = (options) => axios.post('/chainQL', {
  query: `
  {
  listLedger(
    condition: {
      descSorts: ["timestamp"]
      from:${options.page}
      size:${options.limit}
      eq: {
        shardName:${JSON.stringify(options.shard)}
        blockHeight:${options.height}
      }
    }
  )
  {
    total
    ledger {
      actualDifficulty
      contract
      count
      daHash
      dataSources
      depositHash
      errorCode
      expirationDate
      exponent
      fees
      json
      languages
      ledgerCode
      ledgerCodeName
      message
      name
      owner
      parentHash
      reHash
      recall
      receiveAddress
      redemptionTime
      redemptionTimestamp
      remark
      result
      sendAddress
      sendAlgorithm
      sendSign
      statusKey
      statusVersion
      token
      txHash
      unit
      value
      verifyAddress
      verifyAlgorithm
      verifySign
      hash
      hashAlgorithm
      nonce
      promiseCode
      shardName
      timestamp
      version
    }
  }
}` })

// 根据账本哈希查询账本信息
const ledgerInfo = (options) => axios.post('/chainQL', {
  query: `
 {
 listLedger(condition:{
    eq:{
      hash:${JSON.stringify(options.hash)}
      shardName:${JSON.stringify(options.shard)}
    }
  }
  ) {
    ledger {
      blockHeight
      actualDifficulty
      contract
      count
      daHash
      dataSources
      depositHash
      errorCode
      expirationDate
      exponent
      fees
      json
      languages
      ledgerCode
      ledgerCodeName
      message
      name
      owner
      parentHash
      reHash
      recall
      receiveAddress
      redemptionTime
      redemptionTimestamp
      remark
      result
      sendAddress
      sendAlgorithm
      sendSign
      statusKey
      statusVersion
      token
      txHash
      unit
      value
      verifyAddress
      verifyAlgorithm
      verifySign
      hash
      hashAlgorithm
      nonce
      promiseCode
      shardName
      timestamp
      version
      executable
      contractID
      call
      parameter
      run
      namespaces
    }
  }
}
` })

// 账户信息
const accountInfo = (options) => axios.post('/chainQL', {
  query: `
  {
  listAccount(condition:{
    eq:{
      publicKey:${JSON.stringify(options.address)}
    }
  }
  ) {
    account {
      accountAlgorithm
      balance
      deposit
      freeze
      privateKey
      publicKey
      punishment
      nonToken
      tokens
      waitBalance
      hash
      hashAlgorithm
      nonce
      promiseCode
      shardName
      timestamp
      version
    }
  }
}
` })

// 节点信息
const nodeInfo = (options) => axios.post('/chainQL', {
  query: `
  {
  getNode(condition:{
    ip:${JSON.stringify(options.ip)}
    port: ${options.port}
    json: ${JSON.stringify(options.json)}
  }
  ) 
}
` })

// 账户列表
const accountList = (options) => axios.post('/chainQL', {
  query: `
  {
  listAccount(condition:{
    from:${options.page}
    size:${options.limit}
    exists:[
      ${JSON.stringify(options.tokens || options.nonToken)}
    ]
    eq:{
      publicKey:${JSON.stringify(options.publicKey)}
    }
  }
  ) {
   total
    account {
      publicKey
      tokens
      nonToken
    }
 }
}
` })

// 记账列表
const listAccountStatus = (options) => axios.post('/chainQL', {
  query: `
  {
  listAccountStatus(condition:{
    from:${options.page}
    size:${options.limit}
    eq:{
      publicKey:${JSON.stringify(options.publicKey)}
      token:${JSON.stringify(options.token)}
      type:${JSON.stringify(options.type)}
    }
  }
  ) {
   total
   accountStatus {
    publicKey
    token
    type
    value
  }
 }
}
` })

// 状态列表
const listWorkStatus = (options) => axios.post('/chainQL', {
  query: `
  {
  listWorkStatus(condition:{
    from:${options.page}
    size:${options.limit}
    descSorts: ["_doc"]
    id:${JSON.stringify(options.id)}
    eq:{
      parentHash:${JSON.stringify(options.parentHash)}
      statusVersion:${JSON.stringify(options.statusVersion)}
    }
    like:{
      ${options.json ? ('json:' + JSON.stringify(options.json)) : ''}
    }
  }
  ) {
   total
    workStatus {
      id
      json
      parentHash
      statusVersion
    }
 }
}
` })
// 状态详情
const ledgerStatus = (options) => axios.post('/chainQL', {
  query: `
  {
  listLedger(condition:{
    descSorts: ["timestamp"]
    from:${options.page}
    size:${options.limit}
    or:{
      statusKey:${JSON.stringify(options.statusKey)}
      hash:${JSON.stringify(options.statusKey)}
    }
  }
  ) {
   total
    ledger {
      hash
      statusKey
      timestamp
      json
      parentHash
      statusVersion
    }
 }
}
` })

// 账本任务详情列表
const listLedgerTask = (options) => axios.post('/chainQL', {
  query: `
  {
  listLedgerTask(condition:{
    from:${options.page}
    size:${options.limit}
    ledgerTaskOverviewID:${JSON.stringify(options.ledgerTaskOverviewID)}
  }){
    total
    ledgerTask {
      actualDifficulty
      blockHeight
      contract
      count
      daHash
      dataSources
      depositHash
      errorCode
      expirationDate
      exponent
      fees
      hash
      hashAlgorithm
      height
      json
      languages
      ledgerCode
      ledgerCodeName
      ledgerTaskID
      ledgerTaskOverviewID
      message
      name
      nonce
      owner
      parentHash
      promiseCode
      recall
      reHash
      receiveAddress
      redemptionTime
      redemptionTimestamp
      remark
      result
      sendAddress
      sendAlgorithm
      sendSign
      shardName
      statusKey
      statusVersion
      timestamp
      token
      txHash
      unit
      value
      verifyAddress
      verifyAlgorithm
      verifySign
      version
    }
  }
}
` })
// 业务账本信息
const businessLedger = (options) => axios.post('/chainQL', {
  query: `
 {
 businessLedger(condition:{
    eq:{
      hash:${JSON.stringify(options.hash)}
    }
  }
  ) {
    ledgerTask {
      actualDifficulty
      blockHeight
      contract
      count
      daHash
      dataSources
      depositHash
      errorCode
      expirationDate
      exponent
      fees
      hash
      hashAlgorithm
      height
      json
      languages
      ledgerCode
      ledgerCodeName
      ledgerTaskID
      ledgerTaskOverviewID
      message
      name
      nonce
      owner
      parentHash
      promiseCode
      reCall
      reHash
      receiveAddress
      redemptionTime
      redemptionTimestamp
      remark
      result
      sendAddress
      sendAlgorithm
      sendSign
      shardName
      statusKey
      statusVersion
      timestamp
      token
      txHash
      unit
      value
      verifyAddress
      verifyAlgorithm
      verifySign
      version
    }
  }
}
` })

export default {
  listLedger,
  listBlock,
  heightMax,
  difficultyCount,
  accountAlgorithmCount,
  shard,
  blockLine,
  contracts,
  future,
  temp,
  blockChart,
  getNode,
  blockList,
  tableLedger,
  listLedgerType,
  blockDetail,
  blockForHeight,
  blockHeightLedger,
  ledgerInfo,
  accountInfo,
  nodeInfo,
  _tableLedger,
  accountList,
  listAccountStatus,
  listWorkStatus,
  ledgerStatus,
  listLedgerTask,
  businessLedger
}
